const middleware = {}

middleware['auth'] = require('../src/middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['canCheckout'] = require('../src/middleware/canCheckout.js')
middleware['canCheckout'] = middleware['canCheckout'].default || middleware['canCheckout']

middleware['guest'] = require('../src/middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['loginRedirect'] = require('../src/middleware/loginRedirect.js')
middleware['loginRedirect'] = middleware['loginRedirect'].default || middleware['loginRedirect']

middleware['maintenance'] = require('../src/middleware/maintenance.js')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

middleware['redirects'] = require('../src/middleware/redirects.js')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

middleware['search'] = require('../src/middleware/search.js')
middleware['search'] = middleware['search'].default || middleware['search']

middleware['successCheckout'] = require('../src/middleware/successCheckout.js')
middleware['successCheckout'] = middleware['successCheckout'].default || middleware['successCheckout']

middleware['www-redirect'] = require('../src/middleware/www-redirect.js')
middleware['www-redirect'] = middleware['www-redirect'].default || middleware['www-redirect']

export default middleware
